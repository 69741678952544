import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  Action,
  Actions,
  ofAction,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { catchError, finalize, takeUntil, tap, throwError } from 'rxjs';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from 'src/app/interfaces/base-state-model.interface';
import { environment } from 'src/environments/environment';
import {
  PaginationQuery,
  PaginationResponse,
  sanitizePaginationQuery,
} from '../../interfaces/pagination.interface';
import { ProcessingLog } from '../interface/processing-log.interface';

export class LoadProcessingLogs {
  static readonly type = '[ProcessingLog] Load ProcessingLogs';
  constructor(
    public pagination: PaginationQuery,
    public filters: {
      logLevels: string[];
      correlationId: string | null;
      employeeNumber: string | null;
    },
  ) {}
}

export interface ProcessingLogsStateModel extends BaseStateModel {
  logs: ProcessingLog[];
  pagingMeta?: PaginationResponse<ProcessingLog[]>['meta'];
}

@State<ProcessingLogsStateModel>({
  name: 'processingLog',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    logs: [],
    pagingMeta: undefined,
  },
})
@Injectable()
export class ProcessingLogsState {
  private http = inject(HttpClient);
  private actions$ = inject(Actions);

  @Selector()
  static logs(state: ProcessingLogsStateModel) {
    return state.logs;
  }

  @Selector()
  static pagingMeta(state: ProcessingLogsStateModel) {
    return state.pagingMeta;
  }

  @Selector()
  static error(state: ProcessingLogsStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: ProcessingLogsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ProcessingLogsStateModel) {
    return state.loaded;
  }

  @Action(LoadProcessingLogs, {
    cancelUncompleted: true,
  })
  async loadProcessingLogs(
    ctx: StateContext<ProcessingLogsStateModel>,
    action: LoadProcessingLogs,
  ) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    return this.http
      .get<PaginationResponse<any[]>>(
        `${environment.apiUrl}/v1/processing-logs`,
        {
          params: {
            ...(action.pagination
              ? sanitizePaginationQuery(action.pagination)
              : {}),
            ...(action.filters.logLevels.length
              ? { logLevel: action.filters.logLevels }
              : {}),
            correlationId: action.filters.correlationId ?? '',
            employeeNumber: action.filters.employeeNumber ?? '',
          },
        },
      )
      .pipe(
        takeUntil(this.actions$.pipe(ofAction(LoadProcessingLogs))),
        catchError((err) => {
          ctx.patchState({
            error: err,
          });
          return throwError(() => err);
        }),
        tap((res) => {
          const mappedLogs = res.data.map(
            (log) =>
              ({
                context: log.context,
                message: log.message,
                employeeNumber: log.employeeNumber,
                level: log.level,
                date: new Date(log.date),
                correlationId: log.correlationId,
                raw: log.raw,
              }) as ProcessingLog,
          );

          ctx.patchState({ logs: mappedLogs, pagingMeta: res.meta });
        }),
        finalize(() => {
          ctx.patchState({
            loading: false,
            loaded: true,
          });
        }),
      );
  }
}
